import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  const storeOrder = document.querySelector('.store-order');
  const enableSoundButton = document.getElementById("enable-sound");  // Botón para habilitar sonido en móviles
  
  let subscription;
  let soundEnabled = false; // Controla si el sonido está habilitado o no

  if (storeOrder) {
    const storeId = storeOrder.getAttribute('data-store-id');

    // Si ya hay una suscripción activa, la desuscribimos antes de crear una nueva
    if (subscription) {
      subscription.unsubscribe();
      console.log("Desuscrito del canal anterior");
    }

    // Verificar si el sonido está habilitado desde el localStorage
    // if (localStorage.getItem("soundEnabled") === "true") {
    //   soundEnabled = true;
    // }

    // Botón para habilitar sonido en móviles
    if (enableSoundButton) {
      enableSoundButton.addEventListener("click", () => {
        soundEnabled = true;
        // localStorage.setItem("soundEnabled", "true");
        // alert("El sonido ha sido habilitado para nuevas órdenes.");
        enableSoundButton.style.display = 'none';
      });
    }

    // Mantener la habilitación de sonido mediante cualquier toque o interacción mínima
    // document.body.addEventListener('touchstart', () => {
    //   if (!soundEnabled && Notification.permission === "granted") {
    //     soundEnabled = true;
    //     localStorage.setItem("soundEnabled", "true");
    //     console.log('Sonido habilitado por interacción mínima.');
    //   }
    // });

    function playNotificationSound() {
      if (soundEnabled) {
        var sound = new Audio('/sounds/ring_order.wav');
        sound.play().then(() => {
          console.log('Sonido reproducido para nueva orden.');
        }).catch((e) => {
          console.error('Error al reproducir el sonido:', e);
        });
      } else {
        console.log("El sonido está deshabilitado o no permitido.");
      }
    }

    // Crear una nueva suscripción
    subscription = consumer.subscriptions.create(
      { channel: "OrdersChannel", store_id: storeId },
      {
        connected() {
          console.log("Conectado al canal OrdersChannel para la tienda: " + storeId);
        },

        disconnected() {
          console.log("Desconectado del canal OrdersChannel");
        },

        received(data) {
          const ordersList = document.querySelector('.orders-list');
        
          if (data.type === 'new_order' && ordersList) {
            // Renderizar la nueva orden en la lista
            const orderHTML = formatOrderHTML(data.order);
            ordersList.insertAdjacentHTML('beforeend', orderHTML);
            console.log("Nueva orden recibida:", data.order);
        
            // Reproducir sonido si está habilitado
            playNotificationSound();
        
            // Efecto visual para la nueva orden
            const orderElement = document.getElementById(`order-${data.order.id}`);
            if (orderElement) {
              showVisualAlert(orderElement);
              showTopAlert();  // Mostrar alerta de nueva orden
            }
        
          } else if (data.type === 'order_validated') {
            // Eliminar la orden validada de la lista
            const orderElement = document.getElementById(`order-${data.order_id}`);
            if (orderElement) {
              orderElement.parentElement.remove();
              console.log("Orden eliminada:", data.order_id);
            }
          }
        }        
      }
    );
  }

  document.addEventListener('turbolinks:before-cache', () => {
    if (subscription) {
      subscription.unsubscribe();
      console.log("Desuscrito antes de cambiar la vista con Turbolinks");
    }
  });

  // Función para formatear el HTML de la nueva orden
  function formatOrderHTML(order) {
    // Construir HTML para los items de la orden
    const orderItemsHTML = order.order_items.map(item => {
      // Agrupar ingredientes por categorías
      const ingredientsByCategory = item.ingredients.reduce((acc, ingredient) => {
        const categoryName = ingredient.ingredient_category?.name || "Sin Categoría";
        if (!acc[categoryName]) acc[categoryName] = [];
        acc[categoryName].push(ingredient.name);
        return acc;
      }, {});
  
      // Generar HTML de ingredientes por categoría
      const ingredientsHTML = Object.entries(ingredientsByCategory).map(([category, ingredients]) => `
        <div class="mb-2">
          <strong class="text-secondary">${category}:</strong>
          <ul class="list-unstyled ms-3">
            ${ingredients.map(name => `
              <li>
                <i class="bi bi-check-circle-fill text-success me-2"></i> ${name}
              </li>
            `).join('')}
          </ul>
        </div>
      `).join('');
  
      // Generar HTML del producto
      return `
        <li class="list-group-item d-flex flex-column border">
          <span class="badge mb-1 bg-success rounded-pill">${item.quantity} unidades</span>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <span><strong>Producto:</strong> ${item.product.name}</span>
          </div>
          ${item.product.description_as_html ? `
            <div>
              <strong>Descripción:</strong>
              <div class="small product-description">
                ${item.product.description_as_html}
              </div>
            </div>
          ` : ''}
          ${ingredientsHTML ? `<div class="ingredient-details mb-3">${ingredientsHTML}</div>` : ''}
        </li>
      `;
    }).join('');
  
    // Construir HTML de la orden completa
    return `
      <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
        <div class="card ${order.state === 'ready' ? 'order-notified' : ''}" id="order-${order.id}">
          <div class="card-header">
            <strong>Orden: </strong>${order.order_number}
            <span class="badge bg-success float-end mt-4">${order.state}</span>
            <div class="card-text"><strong>Tienda:</strong> ${order.store.name}</div>
            <div class="card-text"><strong>Hora:</strong> ${new Date(order.updated_at).toLocaleString()}</div>
          </div>
          <div class="card-header bg-transparent">
            <h5 class="card-title">Cliente: ${order.account?.name || ''} ${order.account?.last_name || ''}</h5>
            <div class="card-text"><strong class="color-green h5">Total: ${formatCurrency(order.total)}</strong></div>
          </div>
          <ul class="list-group list-group-flush">
            ${orderItemsHTML}
          </ul>
          <div class="card-footer mt-auto">
            <button class="btn ${order.state === 'ready' ? 'btn-secondary text-white' : 'btn-outline-success'} notify-order-ready-btn" 
                    data-order-id="${order.id}" ${order.state === 'ready' ? 'disabled' : ''}>
              ${order.state === 'ready' ? 'Notificación enviada' : 'Notificar al cliente'}
            </button>
          </div>
        </div>
      </div>
    `;
  }
  
  

  // Función para aplicar el efecto visual
  function showVisualAlert(orderElement) {
    orderElement.classList.add("new-order-alert");
    setTimeout(() => {
      orderElement.classList.remove("new-order-alert");
    }, 10000); 
  }

  function showTopAlert() {
    const alertElement = document.getElementById("new-order-alert");
    alertElement.style.display = "block";
    
    // Ocultar la alerta después de 5 segundos
    setTimeout(() => {
      alertElement.style.display = "none";
    }, 15000);
  }
  
  // Función para formatear la moneda
  function formatCurrency(value) {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  }
});

