import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  const accountId = document.body.dataset.accountId;

  if (accountId) {
    consumer.subscriptions.create(
      { channel: "AccountOrderPaidChannel", account_id: accountId },
      {
        received(data) {
          if (data.type === "update_order_state") {
            const orderElement = document.querySelector(`#order-${data.order_id}`);
            if (orderElement) {
              // Actualizar o crear el badge
              let badgeElement = orderElement.querySelector(".receipt-details .badge");
              
              if (!badgeElement) {
                // Crear el badge si no existe
                badgeElement = document.createElement("span");
                badgeElement.className = "badge bg-color-green";
                const receiptDetails = orderElement.querySelector(".receipt-details");
                if (receiptDetails) {
                  receiptDetails.appendChild(badgeElement);
                }
              }

              // Actualizar el contenido del badge
              badgeElement.textContent = "Entregado"; // Texto del estado
              badgeElement.classList.add("bg-color-green"); // Actualizar estilo a verde

              // Ocultar el QR
              const qrContainer = orderElement.querySelector(".qr-code-container");
              if (qrContainer) {
                qrContainer.style.display = "none";
              }
            }
          }
        }
      }
    );
  }
});

