document.addEventListener("turbolinks:load", () => {
  const navbar = document.querySelector(".navbar");
  let lastScrollTop = 0;

  window.addEventListener("scroll", () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    // Verificar si está en el tope superior o inferior
    const atTop = scrollTop <= 0;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

    if (atTop || atBottom) {
      // Limite superior o inferior, no ocultar el navbar
      navbar.style.transform = "translateY(0)";
    } else if (scrollTop > lastScrollTop) {
      // Scroll hacia abajo: ocultar navbar
      navbar.style.transform = "translateY(100%)";
    } else {
      // Scroll hacia arriba: mostrar navbar
      navbar.style.transform = "translateY(0)";
    }

    lastScrollTop = scrollTop;
  });
});