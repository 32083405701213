document.addEventListener("turbolinks:load", () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detectar si es Android
  if (/android/i.test(userAgent)) {
    let isBeforeInstall = false;

    // Detectar si la aplicación está en modo standalone
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

    if (isStandalone) {
      console.log("La PWA ya está instalada y en modo standalone. No se realizará la redirección.");
      return; // Salir si la app está en modo standalone
    }

    // Detectar si el evento beforeinstallprompt está disponible
    // Si detecta el evento significa que la aplicaciónn no esta instalada 
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("Evento beforeinstallprompt detectado. PWA no instalada.");
      isBeforeInstall = true;
    });

    // Verificar después de un tiempo si `beforeinstallprompt` no fue detectado
    setTimeout(() => {
      if (!isBeforeInstall) {
        console.log("No se detectó beforeinstallprompt. Intentando redirigir al intent...");
        
        const currentUrl = window.location.href.replace(/^https?:\/\//, "");
        const intentUrl = `intent://${currentUrl}#Intent;scheme=https;end`;

        window.location.href = intentUrl;
      } else {
        console.log("beforeinstallprompt detectado. No se redirigirá.");
      }
    }, 3000); // 3000 ms para detectar el evento beforeinstallprompt
  } else {
    console.log("No es un dispositivo Android. No se realizará la redirección.");
  }
});
