document.addEventListener("turbolinks:load", () => {
  // Elementos del DOM
  const installButton = document.getElementById("install-button");
  const modal = document.getElementById("install-modal");
  const closeModal = document.getElementById("close-modal");
  const instructionsList = document.getElementById("instructions-list");
  const modalInstallButton = document.getElementById("modal-install-button");
  const carouselContainer = document.getElementById("carousel-container");

  let deferredPrompt;

  if (installButton){
    installButton.style.display = "none";
  }

  const isInStandaloneMode = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;

  if (isInStandaloneMode) {
    console.log("La PWA está en modo standalone.");
    return; 
  }

  // --- Lógica del modal Android---
  // Detectar el evento beforeinstallprompt para el modal
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;

    installButton.style.display = "block";
    modalInstallButton.style.display = "block";

    if (carouselContainer) carouselContainer.style.display = "none";
    if (instructionsList) instructionsList.style.display = "none";

    modalInstallButton.addEventListener("click", () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("El usuario aceptó instalar la PWA");
          } else {
            console.log("El usuario rechazó instalar la PWA");
          }
          deferredPrompt = null;
        });
      }
    });
  });

  // Validación para iOS
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  if (isIos) {
    const isInStandaloneMode = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;
    if (!isInStandaloneMode && installButton) {
      installButton.style.display = "block";
    } else if (installButton){
      installButton.style.display = "none";
    }
  }

  // Mostrar el modal al hacer clic en el botón principal
  installButton.addEventListener("click", () => {
    if (deferredPrompt) {
      instructionsList.innerHTML = "";
      modal.style.display = "block";
      return;
    }

    const { device, browser } = detectDeviceAndBrowser();

    let instructions = "";

    if (device === "iOS" && browser === "Safari") {
      instructions = `
        <li class="mb-2"> Para instalar la aplicación desde ${browser} en ${device}:</li>
        <li class="mb-1">1. Pulsa el botón <span class="material-icons-outlined instruction-icon">ios_share</span> situado abajo de la barra de navegación</li>
        <li>2. Luego selecciona <span class="material-icons-outlined instruction-icon">add_box</span> <strong>Agregar a Inicio</strong></li>
      `;
    } else if (device === "iOS" && browser === "Chrome") {
      instructions = `
        <li class="mb-2"> Para instalar la aplicación desde ${browser} en ${device}:</li>
        <li class="mb-1">1. Pulsa el botón <span class="material-icons-outlined instruction-icon">ios_share</span> en la esquina superior derecha de la barra de navegación</li>
        <li>2. Luego selecciona <span class="material-icons-outlined instruction-icon">add_box</span><strong> Añadir a pantalla de inicio</strong></li>
        
      `;
    } else if (device === "Android" && browser === "Chrome") {
      
      instructions = `
        <li class="mb-2"> Para instalar la aplicación desde ${browser} en ${device}:</li>
        <li>1. Toca el menú (tres puntos) en la esquina superior derecha.</li>
        <li>2. Selecciona <strong>"Añadir a la pantalla de inicio"</strong> en el menú.</li>
      `
      ;
    } else if ((device === "Android" && browser === "Firefox") || (device === "iOS" && browser === "Firefox" ) ) {
      instructions = `
         <li class="mb-2"> Para instalar la aplicación desde ${browser} en ${device}:</li>
         <li>1. Abre esta url en el navegador de <strong>chrome</strong> en android o iOS y sigue las instrucciones</li>
      `;
    }

    if (!deferredPrompt && carouselContainer) {
      carouselContainer.style.display = "block";
    }

    instructionsList.innerHTML = instructions;
    modalInstallButton.style.display = "none";
    modal.style.display = "block";
  });

  // Cerrar el modal
  closeModal.addEventListener("click", () => {
    modal.style.display = "none";
  });

  // Función para detectar el dispositivo y navegador
  function detectDeviceAndBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let device = "Otro";
    let browser = "Desconocido";

    if (/android/i.test(userAgent)) device = "Android";
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) device = "iOS";

    if (/firefox|fxios/i.test(userAgent)) browser = "Firefox";
    else if (/chrome|crios/i.test(userAgent)) browser = "Chrome";
    else if (/safari/i.test(userAgent) && !/chrome|crios/i.test(userAgent)) browser = "Safari";

    return { device, browser };
  }
});
