document.addEventListener("turbolinks:load", async() => {
  const container = document.getElementById('paymentBrick_container')
  if(container){

    const amount = document.getElementById('paymentBrick_container').dataset.amount
    const public_key = document.getElementById('paymentBrick_container').dataset.publicKey
    // const public_key = 'TEST-ee472852-415a-41a1-8661-54cd0924fa15' // test
    const email = document.getElementById('paymentBrick_container').dataset.email
    const mp = new MercadoPago(public_key, { locale: 'es-CL'})
    const bricksBuilder = mp.bricks()

    const renderPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          marketplace: true,
          amount: amount,
          payer: { entityType: "individual", email: email},
        },
        customization: {
          visual: { hideRedirectionPanel: true, style: {theme: "bootstrap"} },
          paymentMethods: { creditCard: "all", debitCard: "all", maxInstallments: 1 },
        },
        callbacks: {
          onReady: () => console.log('Brick listo'),
          onSubmit: (formData) => {
            const container = document.getElementById('paymentBrick_container')
            const storeId = container.getAttribute('data-store-id')

            return new Promise((resolve, reject) => {
              fetch(`/stores/${storeId}/process_payment`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
                body: JSON.stringify(formData),
              })
              .then(response => response.json())
              .then(paymentResult => {
                let redirectUrl;
                if (paymentResult.status === 'approved') {
                  let redirectUrl_base = `/stores/${storeId}/response_payment?payment_id=${paymentResult.payment_id}` 
                  redirectUrl = redirectUrl_base += '&status=approved'
                } else if (paymentResult.status === 'pending' && paymentResult.status_detail === 'pending_challenge') {
                  redirectUrl = `/stores/${storeId}/response_payment?payment_id=${paymentResult.payment_id}&external_resource_url=${encodeURIComponent(paymentResult.three_ds_info.external_resource_url)}&creq=${encodeURIComponent(paymentResult.three_ds_info.creq)}`;
                } else {
                  redirectUrl = `/stores/${storeId}/response_payment?payment_id=${paymentResult.payment_id}`;
                }
              
                window.location.href = redirectUrl;
                resolve(paymentResult)
              })              
              .catch(error => {
                console.error('Error processing payment:', error.message || 'Unknown error');
                reject(error)
              });
            });
          },
          onError: (error) => console.error('Error en Brick:', error),
        },
      };
      window.paymentBrickController = await bricksBuilder.create('payment', 'paymentBrick_container', settings);
    };

    function unmountBrick() {
      if (window.paymentBrickController) {
        window.paymentBrickController.unmount();
        window.paymentBrickController = null;
        console.log('Brick unmounted');
      }
    }

    document.addEventListener('turbolinks:before-visit', unmountBrick)
    window.addEventListener('beforeunload', unmountBrick)

    await renderPaymentBrick(bricksBuilder);
  }
});