window.initializeIngredientToggle = function () {
  document.querySelectorAll(".show-more-btn").forEach(button => {
    button.removeEventListener("click", toggleIngredientList); // Elimina eventos previos
    button.addEventListener("click", toggleIngredientList); // Agrega el evento
  });
};

window.toggleIngredientList = function (e) {
  const button = e.currentTarget;
  const parent = document.querySelector(button.dataset.target);
  if (!parent) {
    console.error(`No se encontró el contenedor para el botón con data-target: ${button.dataset.target}`);
    return;
  }

  const content = parent.querySelector(".ingredient-list");
  if (!content) {
    console.error("No se encontró el contenido de la lista de ingredientes.");
    return;
  }

  const collapsedHeight = parent.dataset.maxHeight || "1.4em";

  if (parent.classList.contains("expanded")) {
    content.style.maxHeight = collapsedHeight;
    button.textContent = "Mostrar más";
  } else {
    content.style.maxHeight = content.scrollHeight + "px";
    button.textContent = "Mostrar menos";
  }

  parent.classList.toggle("expanded");
};

// Inicializar en los eventos relevantes
document.addEventListener("turbolinks:load", () => {
  window.initializeIngredientToggle();
});

document.addEventListener("ajax:success", () => {
  window.initializeIngredientToggle();
});
